body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  display: flex;
  flex-wrap: wrap;
}
.container > div {
  flex: 1 1 auto;
  flex-basis: 30%;
}

@media (max-width: 777px) {
  .container > div {
    flex-basis: 90%;
  }
}


.responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}