.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 50%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

#progressbar {
    position: absolute;
    bottom: 0;
    /* (height of inner div) / 2 + padding */
    width: 100%;
    height: 100%;
  }
  
  #progressbar>div {
    background-color: rgba(155 ,30 ,30, 0.4);
    height: 100%;
  }

  .breath_regulator {
    margin: 0;
    padding: 0;
    position: relative;
    width: 70%;
  }

  .breath_regulator div {
    
  }


  @import url('https://fonts.googleapis.com/css?family=Bowlby+One+SC');

  .breath_regulator a:link {
    background-color: black;
  }

  .breath_regulator h3 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: 'Bowlby One SC', cursive;
    font-size: 6vw;
    background: sandybrown;
  }

  .breath_regulator h4 {
    margin: 0;
    padding: 5px;
    text-align: center;
    font-size: 3vw;
    font-style: oblique;
    background: black;
    color: white;
  }

  .breath_regulator p {
    padding: 5px;
    background:rgb(5, 78, 78);
    margin: 0;
    overflow: hidden;
    color: rgb(114, 179, 169);
    text-align: center;
  }

  .breath_regulator .co {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
  }

  .breath_regulator .co > * {
    flex: 0 0 100%;
   
  }
  
  .list-display {
    background-color: aquamarine;
    height: 100%;
  }

  .breath_regulator a {
    color: brown;
    display: block;
    text-align: center;
    padding: 0;
    font-size: 24px;
    z-index: 1;
    text-decoration: none;
}