.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Aligner-item {
    max-width: 50%;
  }
  
  .Aligner-item--top {
    align-self: flex-start;
  }
  
  .Aligner-item--bottom {
    align-self: flex-end;
  }
  
  .Aligner h1 {
    animation: pulse 5.5s infinite;
  }
  
  @keyframes pulse {
    0% {
      color:antiquewhite;
    }
    100% {
      color: #ffffff;
    }
  }

  #logo {
    margin: 30px;
  }