.tabs {
    margin: 11px;
   
}

.tabs .tab-content {
    padding: 10px;
    background: rgba(9, 46, 66, 0.75);
}

.tabs .selected {
    background: rgba(9, 46, 66, 0.75);
}

.tabs .unselected {
    background: #5f5757;
}

.tabs ul {
    list-style: none ;
    display: flex;
    margin: 0;
    padding: 0;
    background: none;
}

.tabs li {
    border-bottom: none;
    padding: 20px;
    border-radius: 20px 20px 0 0;
}
