@import url('https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap');



.interesting {
   
    
}

.interesting .feature {
    background: rgba(0, 0, 0, 0.5);
}

.interesting .Video {
    background: rgba(13, 73, 37, 0.6);
    
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    
}

.interesting .Video a {
    background: rgba(5, 5, 68, 0.6);
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    color: rgb(184, 171, 154);
    text-align: center;
}

.feature {
    margin: 10px;
    padding: 0px;
    position: relative;
    overflow: hidden;
    border: 1;
    background: rgba(100, 100, 100, 0.2);
    padding-bottom: 10px;
}

.feature img {
  position:absolute;
  width: 100%;
  height: auto;
  z-index: -1;
}

.feature h1 {
    margin: 0;
    padding: 11px;
    background: rgba(100, 100, 100, 0.75);
    text-align: center;
    font-family: 'Teko', sans-serif;
    border-radius: 0 0 20px 20px;
}

.feature h2 {
    margin: 0;
    padding: 11px;
    background: rgba(100, 100, 100, 0.75);
    text-align: center;
    font-family: 'Teko', sans-serif;
    border-radius: 20px;
    font-size: 32px;
}

.feature ul {
    background: rgba(0, 0, 0, 0.5);
    margin: 11px;
    padding: 22px;
    border-radius: 22px;
}

.feature li {
    color: antiquewhite;
    margin-left: 22px;
   
}

.feature .children 
{
   margin: auto;
    width: 88%;
    padding: 9px;
    color: antiquewhite;
    background: rgba(100, 100, 100, 0.5);
    padding-bottom: 22px;
    border-radius: 0 0 20px 20px;
}

.feature .children p
{
   margin: 0;
}

.feature a.link  {
    background-color: brown;
    color: antiquewhite;
    padding: 9px;
    margin: 3px;
}