@import url(https://fonts.googleapis.com/css?family=Bowlby+One+SC);
@import url(https://fonts.googleapis.com/css2?family=Teko:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
  display: flex;
  flex-wrap: wrap;
}
.container > div {
  flex: 1 1 auto;
  flex-basis: 30%;
}

@media (max-width: 777px) {
  .container > div {
    flex-basis: 90%;
  }
}


.responsive {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}
header {
  background: black;
}

header h1 {
  padding: 0;
  margin: 0;
  display: block;
  text-align: center;
  color: antiquewhite;
}

header ul {
  padding: 0;
  margin: 0;
  display: block;
  background: antiquewhite;
  color: black;
  text-align: center;
}

header li {
  border: 1px solid black;
}
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 50%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}

#progressbar {
    position: absolute;
    bottom: 0;
    /* (height of inner div) / 2 + padding */
    width: 100%;
    height: 100%;
  }
  
  #progressbar>div {
    background-color: rgba(155 ,30 ,30, 0.4);
    height: 100%;
  }

  .breath_regulator {
    margin: 0;
    padding: 0;
    position: relative;
    width: 70%;
  }

  .breath_regulator div {
    
  }

  .breath_regulator a:link {
    background-color: black;
  }

  .breath_regulator h3 {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: 'Bowlby One SC', cursive;
    font-size: 6vw;
    background: sandybrown;
  }

  .breath_regulator h4 {
    margin: 0;
    padding: 5px;
    text-align: center;
    font-size: 3vw;
    font-style: oblique;
    background: black;
    color: white;
  }

  .breath_regulator p {
    padding: 5px;
    background:rgb(5, 78, 78);
    margin: 0;
    overflow: hidden;
    color: rgb(114, 179, 169);
    text-align: center;
  }

  .breath_regulator .co {
     display: flex;
     flex-wrap: wrap;
     align-items: center;
  }

  .breath_regulator .co > * {
    flex: 0 0 100%;
   
  }
  
  .list-display {
    background-color: aquamarine;
    height: 100%;
  }

  .breath_regulator a {
    color: brown;
    display: block;
    text-align: center;
    padding: 0;
    font-size: 24px;
    z-index: 1;
    text-decoration: none;
}
.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .Aligner-item {
    max-width: 50%;
  }
  
  .Aligner-item--top {
    align-self: flex-start;
  }
  
  .Aligner-item--bottom {
    align-self: flex-end;
  }
  
  .Aligner h1 {
    -webkit-animation: pulse 5.5s infinite;
            animation: pulse 5.5s infinite;
  }
  
  @-webkit-keyframes pulse {
    0% {
      color:antiquewhite;
    }
    100% {
      color: #ffffff;
    }
  }
  
  @keyframes pulse {
    0% {
      color:antiquewhite;
    }
    100% {
      color: #ffffff;
    }
  }

  #logo {
    margin: 30px;
  }
.interesting {
   
    
}

.interesting .feature {
    background: rgba(0, 0, 0, 0.5);
}

.interesting .Video {
    background: rgba(13, 73, 37, 0.6);
    
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    
}

.interesting .Video a {
    background: rgba(5, 5, 68, 0.6);
    margin: 10px;
    padding: 10px;
    border-radius: 20px;
    color: rgb(184, 171, 154);
    text-align: center;
}

.feature {
    margin: 10px;
    padding: 0px;
    position: relative;
    overflow: hidden;
    border: 1;
    background: rgba(100, 100, 100, 0.2);
    padding-bottom: 10px;
}

.feature img {
  position:absolute;
  width: 100%;
  height: auto;
  z-index: -1;
}

.feature h1 {
    margin: 0;
    padding: 11px;
    background: rgba(100, 100, 100, 0.75);
    text-align: center;
    font-family: 'Teko', sans-serif;
    border-radius: 0 0 20px 20px;
}

.feature h2 {
    margin: 0;
    padding: 11px;
    background: rgba(100, 100, 100, 0.75);
    text-align: center;
    font-family: 'Teko', sans-serif;
    border-radius: 20px;
    font-size: 32px;
}

.feature ul {
    background: rgba(0, 0, 0, 0.5);
    margin: 11px;
    padding: 22px;
    border-radius: 22px;
}

.feature li {
    color: antiquewhite;
    margin-left: 22px;
   
}

.feature .children 
{
   margin: auto;
    width: 88%;
    padding: 9px;
    color: antiquewhite;
    background: rgba(100, 100, 100, 0.5);
    padding-bottom: 22px;
    border-radius: 0 0 20px 20px;
}

.feature .children p
{
   margin: 0;
}

.feature a.link  {
    background-color: brown;
    color: antiquewhite;
    padding: 9px;
    margin: 3px;
}
.book h3 {
    background: black;
    margin: 0;
    padding: 0;
    text-align: right;
    font-size: 14px;
    padding-right: 10px;
}

a.book {
    color: cornsilk;
    text-decoration: none;
    text-justify: newspaper;
    line-height: 33px;
}

.book p  {
    padding: 10px;
}
.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
/* 
#video_box {
    background: rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

} */



#video_box  {
    border: olive 10px solid;
    border-radius: 2px;
}

.Video a {
   color: #000;
   font-size: 120%;
   text-align: right;
   display: block;
}

#timeline {
    background: red;
    position: relative;
}

.timeslot {
    background: olivedrab;
    white-space: nowrap;
    overflow: hidden;
}
.tabs {
    margin: 11px;
   
}

.tabs .tab-content {
    padding: 10px;
    background: rgba(9, 46, 66, 0.75);
}

.tabs .selected {
    background: rgba(9, 46, 66, 0.75);
}

.tabs .unselected {
    background: #5f5757;
}

.tabs ul {
    list-style: none ;
    display: flex;
    margin: 0;
    padding: 0;
    background: none;
}

.tabs li {
    border-bottom: none;
    padding: 20px;
    border-radius: 20px 20px 0 0;
}

