.book h3 {
    background: black;
    margin: 0;
    padding: 0;
    text-align: right;
    font-size: 14px;
    padding-right: 10px;
}

a.book {
    color: cornsilk;
    text-decoration: none;
    text-justify: newspaper;
    line-height: 33px;
}

.book p  {
    padding: 10px;
}