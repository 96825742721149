.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
/* 
#video_box {
    background: rgba(0,0,0,0.9);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

} */



#video_box  {
    border: olive 10px solid;
    border-radius: 2px;
}

.Video a {
   color: #000;
   font-size: 120%;
   text-align: right;
   display: block;
}

#timeline {
    background: red;
    position: relative;
}

.timeslot {
    background: olivedrab;
    white-space: nowrap;
    overflow: hidden;
}