header {
  background: black;
}

header h1 {
  padding: 0;
  margin: 0;
  display: block;
  text-align: center;
  color: antiquewhite;
}

header ul {
  padding: 0;
  margin: 0;
  display: block;
  background: antiquewhite;
  color: black;
  text-align: center;
}

header li {
  border: 1px solid black;
}